import type {
    EventInfo,
    OrderInfoCustomsDetail,
    Organization,
    SearchRfq,
    SearchRfqCustomsDetail,
    SearchRfqHandlingUnit,
    UserInfo,
} from "@/app/model";
import moment from "moment";
import type { CurrencyRates } from "../../definitions/CurrencyRates";
import type { OrderDetails } from "../../definitions/OrderDetails";
import type { OrderFilter, OrderListFiltering } from "../../definitions/OrderList";
import type { Snackbar } from "../../definitions/Snackbar";
import type { Store, StoreAppState } from "../../definitions/Store";
import { LazrCategories } from "../../lib/constants";
import { LAST7DAYS } from "../constants";

import type { ApplyPaymentInfo } from "@/app/ui/definitions/ApplyPaymentInfo";
import type { DefaultAddresses } from "@/app/ui/definitions/DefaultAddresses";
import type { LaneListFiltering } from "@/app/ui/definitions/LaneList";
import type { PendingPriceReview } from "@/app/ui/definitions/PendingPriceReview";
import { AccessorialTypeFilter, TaxType } from "@lazr/enums";
import { PaymentTypeOption } from "@lazr/enums";
import { TrackingFilterStatus } from "@lazr/enums";
import {
    BillingChargeType,
    BillingStatus,
    ClientProfitabilityPeriod,
    Currency,
    DensityUnit,
    DimensionUnit,
    EdiOrderStatus,
    EquipmentType,
    InvoiceStatus,
    InvoiceType,
    Language,
    OrderStatus,
    OrganizationStatus,
    RequestType,
    RfqStatus,
    TransportType,
    VolumeUnit,
    WeightUnit,
    OrganizationType,
} from "@lazr/openapi-client";
import { deepCopy } from "@lazr/utilities";
import type { AddressFilter, AddressListFiltering } from "../../definitions/AddressList";
import type { ConsolidationFilter, ConsolidationPaymentListFiltering } from "../../definitions/ConsolidationPaymentList";
import type { EdiFilter, EdiListFiltering } from "../../definitions/EdiList";
import type { InvoiceFilter, InvoiceListFiltering, NewConsolidationInvoiceFilter } from "../../definitions/InvoiceList";
import { type MarketPlace, MarketPlaceStep } from "../../definitions/MarketPlace";
import type { OrganizationFilter, OrganizationListFiltering } from "../../definitions/OrganizationList";
import type { UserFilter, UserListFiltering } from "../../definitions/UserList";

// New UI
import { initialMarketPlace as initialNewMarketPlace } from "@/app/ui-new/redux/store/InitialState";

export enum WithOrderCreated {
    YES = "Yes",
    NO = "No",
}

export const initialUserInfo: UserInfo = {
    user: null,
    isSignedIn: false,
    defaultUserCurrency: Currency.CAD,
};

export const initialUserOrganization: Organization | null = null;

export const initialTheme = 0;

export const initialSnackbar: Snackbar = {
    open: false,
    severity: "success",
    origin: {
        vertical: "top",
        horizontal: "right",
    },
    message: "",
    autoHideDuration: 5000,
};

export const initialHandlingUnit: Partial<SearchRfqHandlingUnit> = {
    heightUnit: DimensionUnit.IN,
    lengthUnit: DimensionUnit.IN,
    widthUnit: DimensionUnit.IN,
    weightUnit: WeightUnit.LB,
    totalWeightUnit: WeightUnit.LB,
    totalVolumeUnit: VolumeUnit.FT3,
    densityUnit: DensityUnit.LB_FT3,
    class: "",
    totalVolume: 0,
};

export const initialCustomsDetails: Partial<SearchRfqCustomsDetail> = {
    id: undefined,
    productIndicatorIsUsmca: false,
    productIndicatorFda: false,
    productIndicatorTextile: false,
    documentIndicatorUsmca: false,
    documentIndicatorFda: false,
    documentIndicatorFcc: false,
    senderIsProducerIndicator: false,
    textileManufacturer: null,
    unitWeight: 0,
    weightUnit: WeightUnit.LB,
    pieces: 0,
    handlingUnitIds: [],
};

export const initialDutyBillAddress: Partial<SearchRfq> = {
    dutyBillToCity: null,
    dutyBillToContactEmail: null,
    dutyBillToCompanyName: null,
    dutyBillToContactName: null,
    dutyBillToContactPhone: null,
    dutyBillToContactPhoneExt: null,
    dutyBillToCountry: null,
    dutyBillToName: null,
    dutyBillToPostalCode: null,
    dutyBillToState: null,
    dutyBillToStreetAddress: null,
    dutyBillToStreetAddress2: null,
    dutyBillToAccountNumber: null,
};

export const initialCustomsBrokerAddress: Partial<SearchRfq> = {
    customsBrokerCity: null,
    customsBrokerEmail: null,
    customsBrokerContactName: null,
    customsBrokerContactPhone: null,
    customsBrokerContactPhoneExt: null,
    customsBrokerCountry: null,
    customsBrokerPostalCode: null,
    customsBrokerState: null,
    customsBrokerStreetAddress: null,
    customsBrokerStreetAddress2: null,
    customsBrokerAccountNumber: null,
};

export const initialCustomsInfo: Partial<SearchRfq> = {
    importExport: null,
    incotermsType: null,
    billDutiesToParty: null,
    commercialInvoiceDocumentIndicator: true,
    commercialInvoiceUserProvided: false,
    dutyBillToCity: null,
    dutyBillToContactEmail: null,
    dutyBillToCompanyName: null,
    dutyBillToContactName: null,
    dutyBillToContactPhone: null,
    dutyBillToContactPhoneExt: null,
    dutyBillToCountry: null,
    dutyBillToName: null,
    dutyBillToPostalCode: null,
    dutyBillToState: null,
    dutyBillToStreetAddress: null,
    dutyBillToStreetAddress2: null,
    dutyBusinessRelated: null,
    dutyCurrency: null,
    reasonForExport: null,
    nonDeliveryHandling: null,
    otherReasonForExport: null,
    customsBroker: null,
    customsBrokerCity: null,
    customsBrokerEmail: null,
    customsBrokerContactName: null,
    customsBrokerContactPhone: null,
    customsBrokerContactPhoneExt: null,
    customsBrokerCountry: null,
    customsBrokerPostalCode: null,
    customsBrokerState: null,
    customsBrokerStreetAddress: null,
    customsBrokerStreetAddress2: null,
    customsBrokerAccountNumber: null,
    taxIdentificationNumber: null,
};

export const initialHazmatDetail: Partial<SearchRfq> = {
    hazmatClass: null,
    hazmatMode: null,
    hazmatDeclarationDocumentIndicator: null,
};

export const initialOrderFilter: OrderFilter = {
    orderNumber: "",
    clientRefNumber: "",
    threePlRefNumber: "",
    originPoNumber: "",
    destinationPoNumber: "",
    handlingUnitPoNumber: "",
    ediTenderNumber: "",
    shipmentIdentifierValue: "",
    carrier: null,
    createdBy: null,
    clientOrganization: null,
    threePlOrganization: null,
    createdDateFrom: "",
    createdDateTo: "",
    readyDateFrom: "",
    readyDateTo: "",
    locationOrigin: "",
    locationDestination: "",
    salesRep: null,
    threePlAgent: null,
    transportType: {
        [TransportType.LTL]: false,
        [TransportType.FTL]: false,
        [TransportType.OTHER]: false,
        [TransportType.PARCEL]: false,
        [TransportType.ENVELOPE]: false,
        [TransportType.PAK]: false,
    },
    orderStatus: {
        [OrderStatus.PRELOAD]: false,
        [OrderStatus.GHOST]: false,
        [OrderStatus.DRAFT]: false,
        [OrderStatus.NEGOTIATION]: false,
        [OrderStatus.RESERVATION]: false,
        [OrderStatus.TRANSPORT]: false,
        [OrderStatus.BILLING]: false,
        [OrderStatus.COMPLETED]: false,
    },
    lazrCategories: {
        [LazrCategories.COMMON_CARRIER]: false,
        [LazrCategories.CONTRACT_CARRIER]: false,
        [LazrCategories.PRIVATE_FLEET_CARRIER]: false,
        [LazrCategories.INTERMODAL]: false,
        [LazrCategories.OTHER]: false,
    },
    requestType: {
        [RequestType.CUSTOM_QUOTE]: false,
        [RequestType.WITH_COVERAGE]: false,
        [RequestType.EDI]: false,
    },
    serviceLevelStandard: false,
    serviceLevelExpedite: false,
    serviceLevelAccelerated: false,
    serviceLevelGuaranteed: false,
    equipmentType: {
        [EquipmentType.DRYBOX]: false,
        [EquipmentType.REEFER]: false,
        [EquipmentType.FLATBED]: false,
        [EquipmentType.SPECIALIZED]: false,
        [EquipmentType.FLATBED_ROLL_TITE]: false,
        [EquipmentType.OTHER]: false,
    },
    billingStatus: {
        [BillingStatus.PENDING_PRICE_REVIEW]: false,
        [BillingStatus.PENDING_INVOICE]: false,
        [BillingStatus.AWAITING_PAYMENT]: false,
        [BillingStatus.PENDING_PAYMENT]: false,
        [BillingStatus.PAYMENT_PAST_DUE]: false,
    },
    trackingFilterStatus: {
        [TrackingFilterStatus.DISPATCHED]: false,
        [TrackingFilterStatus.PICKUP]: false,
        [TrackingFilterStatus.IN_TRANSIT]: false,
        [TrackingFilterStatus.DELAYED]: false,
        [TrackingFilterStatus.DELIVERY]: false,
        [TrackingFilterStatus.EXCEPTION]: false,
    },
    accessorialType: {
        [AccessorialTypeFilter.IS_CALL_TAG]: false,
    },
};
export const initialInvoiceFilter: InvoiceFilter = {
    orderId: "",
    orderNumber: "",
    number: "",
    status: null,
    billingPo: "",
    clientOrganization: null,
    type: {
        [InvoiceType.RECEIVABLE]: true,
        [InvoiceType.PAYABLE]: false,
        [InvoiceType.EXTERNAL]: false,
    },
    maxDifference: "",
    minDifference: "",
    pickUpDateFrom: "",
    pickUpDateTo: "",
    dueDateFrom: "",
    dueDateTo: "",
    transportType: undefined,
    billToAddressId: "",
    withPOD: false,
};

export const initialNewConsolidationInvoiceFilter: NewConsolidationInvoiceFilter = {
    billToAddress: null,
    clientOrganization: null,
    consolidationType: null,
    currency: null,
    invoiceDueDateFrom: "",
    invoiceDueDateTo: "",
    pickUpDateFrom: "",
    pickUpDateTo: "",
    transportType: null,
};

export const initialConsolidationPaymentFilter: ConsolidationFilter = {
    orderId: "",
    status: [InvoiceStatus.PENDING, InvoiceStatus.PARTIALLY_PAID],
    clientOrganization: null,
    types: [InvoiceType.RECEIVABLE],
    dueDateFrom: "",
    dueDateTo: "",
    pickUpDateFrom: "",
    pickUpDateTo: "",
    billToAddressId: "",
    currency: null,
    excludeCreditNotes: undefined,
    excludeXeroInvoices: undefined,
};

export const initialOrganizationFilter: OrganizationFilter = {
    organizationName: "",
    organizationStatuses: {
        [OrganizationStatus.APPROVED]: null,
        [OrganizationStatus.BLOCKED]: null,
        [OrganizationStatus.EVALUATING]: null,
        [OrganizationStatus.INACTIVE]: null,
        [OrganizationStatus.LIMITED]: null,
    },
    currentPage: 1,
    salesRep: null,
    isGmx: undefined,
    organizationTypes: {
        [OrganizationType.THREE_PL]: null,
        [OrganizationType.CLIENT]: null,
        [OrganizationType.SYSTEM]: null,
        [OrganizationType.CARRIER]: null,
    },
};

export const initialOrderListFiltering: OrderListFiltering = {
    filteredByOrganizationId: "",
    filters: initialOrderFilter,
    organizationList: [],
    otherUserInfoList: [],
    otherThreePlAgentList: [],
    carrierList: [],
    salesRepList: [],
};
export const initialInvoiceListFiltering: InvoiceListFiltering = {
    filters: initialInvoiceFilter,
    newConsolidationFilters: initialNewConsolidationInvoiceFilter,
    filteredByOrganizationId: "",
    organizationList: [],
};
export const initialConsolidationPaymentListFiltering: ConsolidationPaymentListFiltering = {
    filters: initialConsolidationPaymentFilter,
    organizationList: [],
};
export const initialOrderEvents: EventInfo[] = [];

export const initialOrganizationListFiltering: OrganizationListFiltering = {
    filters: initialOrganizationFilter,
    salesRepList: [],
};

export const resetMarketPlace: Partial<MarketPlace> = {
    order: {
        billingCurrency: null,
        status: OrderStatus.GHOST,
        ediOrderId: null,
        rfq: {
            ...initialCustomsInfo,
            accessorials: [],
            handlingUnits: [],
            quotes: [],
            customQuoteRequested: false,
            status: RfqStatus.GHOST,
            coverageCommodityType: undefined,
            shipmentCoverageValue: 0,
            isBookedWithCoverage: false,
            isCoverageExclusionsAccepted: false,
            originPickupDate: moment().format("YYYY-MM-DD"),
            originAddressType: null,
            destinationAddressType: null,
        },
    },
    originSaveToAddressBook: false,
    destinationSaveToAddressBook: false,
    originFullAddress: null,
    destinationFullAddress: null,
    dutyBillFullAddress: null,
    customsBrokerFullAddress: null,
    requiredOriginFieldsEdited: false,
    requiredDestinationFieldsEdited: false,
    // @ts-ignore
    lastCompletedStep: -1,
    currentStep: MarketPlaceStep.SHIPMENT_INFORMATION,
    isQuoteFetchingComplete: false,
    marketPlaceErrors: {
        rfqIsDirty: {},
        cargoIsDirty: [{}],
        rfqErrors: {},
        cargoErrors: [{}],
        customsDetailErrors: [{}],
        customsDetailIsDirty: [{}],
        rfqCustomsErrors: {},
        rfqCustomsIsDirty: {},
        isSubmitted: false,
        handlingUnitLinkErrors: [],
        handlingUnitLinkIsDirty: [],
    },
    invalidationDialog: {},
    isCoverageExclusionsAccepted: false,
    promptModalLeave: false,
    originAddressBookAddress: null,
    destinationAddressBookAddress: null,
    selectedValidatedOriginAddress: null,
    selectedValidatedDestinationAddress: null,
    openReasonsModal: false,
    openWarningGroundModal:false,
    isPrepared: false,
    paymentType: PaymentTypeOption.PREPAID,
    collect: null,
    selectedLane: null,
    marketplaceVersion: 1,
    priceResetTriggered: false,
};

// @ts-ignore FIXME: Leftover error after fixing the frontend build on 2022-05-04 by mbiard
export const initialMarketPlace: MarketPlace = {
    ...resetMarketPlace,
    coverageCommodityTypeList: [],
    packageTypeList: [],
    handlingUnitAccessorialList: [],
    serviceAccessorialList: [],
    originAccessorialList: [],
    destinationAccessorialList: [],
};

export const initialCurrencyRates: CurrencyRates = {
    [Currency.CAD]: {
        [Currency.USD]: 1,
        [Currency.CAD]: 1,
    },
    [Currency.USD]: {
        [Currency.CAD]: 1,
        [Currency.USD]: 1,
    },
};

export const initialSystemStats = {
    timeRange: LAST7DAYS,
    startDate: moment().subtract(6, "days").startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
    clientOrganization: null,
    threePlOrganization: null,
    salesRep: null,
    clientProfitabilityPeriod: ClientProfitabilityPeriod.MONTH,
    isGMX: false,
};

export const initialAppState: StoreAppState = {
    isUserFetched: false,
    organizationList: [],
    isOrganizationFetched: false,
    currencyList: [],
    isCurrencyFetched: false,
    languageList: [],
    isLanguageFetched: false,
    localeList: [],
    isLocaleFetched: false,
    timezoneList: [],
    isTimezoneFetched: false,
    userTitleList: [],
    isUserTitleFetched: false,
};

export const initialDefaultAddressListState: DefaultAddresses = {
    billingAddressList: [],
    shippingAddressList: [],
};

export const initialQuoteCostCharge = {
    code: "",
    description: "",
    rawCad: null,
    rawUsd: null,
    marginCad: 0,
    marginUsd: 0,
    totalCad: null,
    totalUsd: null,
};
export const initialCoverageCostCharge = {
    code: BillingChargeType.LAZR_COVERAGE,
    description: "",
    rawCad: null,
    rawUsd: null,
    marginCad: 0,
    marginUsd: 0,
    totalCad: null,
    totalUsd: null,
};

export const initialCustomQuote = {
    quoteCostCharges: [
        {
            code: BillingChargeType.FREIGHT,
            description: "",
            rawCad: null,
            rawUsd: null,
            marginCad: 0,
            marginUsd: 0,
            totalCad: null,
            totalUsd: null,
        },
        {
            code: BillingChargeType.FUEL_SURCHARGE,
            description: "",
            rawCad: null,
            rawUsd: null,
            marginCad: 0,
            marginUsd: 0,
            totalCad: null,
            totalUsd: null,
        },
    ],
    additionalInfo: "",
    threeplEta: null,
    threeplEstimatedTransitMin: 0,
    threeplEstimatedTransitMax: 0,
    threeplTotalCadCost: 0,
    threeplTotalUsdCost: 0,
    threeplTotalCadPrice: 0,
    threeplTotalUsdPrice: 0,
    threeplCurrency: null,
    threeplValidUntil: moment().add(5, "days").format("YYYY-MM-DDTHH:mm:ssZ"),
};
export const initialApplyPaymentInfo: ApplyPaymentInfo = {
    invoices: [],
    consolidations: [],
};
export const initialPendingPriceReview: PendingPriceReview = {
    pendingPriceReviewErrors: {
        chargesErrors: [{}, {}, {}],
        chargesAreDirty: [{}],
        isSubmitted: false,
    },
    pendingPriceReviewCostCharges: [],
    pendingPriceReviewTotalCadCost: 0,
    pendingPriceReviewTotalUsdCost: 0,
    pendingPriceReviewTotalCadPrice: 0,
    pendingPriceReviewTotalUsdPrice: 0,
    pendingPriceReviewCurrency: Currency.CAD,
};
export const initialOrderDetails: OrderDetails = {
    ...initialCustomQuote,
    orderDetailsMarketplaceErrors: {
        quoteErrors: {},
        chargesErrors: [{}, {}, {}],
        orderDetailsErrors: {},
        chargesAreDirty: [{}],
        isSubmitted: false,
    },
    order: null,
    editableOrder: {},
    orderSummaryErrors: {
        errors: {},
        customsDetailErrors: [],
        customsInfoErrors: {},
        handlingUnitLinkErrors: [],
        isSubmitted: false,
    },
    orderCloseIntervals: [],
    charges: [],
    lastInvoicePickupDate: null,
    selectedTransportInvoiceDocuments: [],
    selectedTransportInvoiceNumber: null,
    selectedTransportTaxes: [],
    selectedClientTaxes: [],
    selectedLazrShareTaxes: [
        {
            type: TaxType.NO_TAX,
            amount: 0,
            rate: 0,
        },
    ],
    invoiceDate: null,
    calcultatedDueDate: null,
    paymentTermsDays: null,
    orderBillingErrors: {
        isSubmitted: false,
    },
};

export const initialOrderDetailCustomsDetails: Partial<OrderInfoCustomsDetail> = {
    id: undefined,
    productIndicatorIsUsmca: false,
    productIndicatorFda: false,
    productIndicatorTextile: false,
    documentIndicatorUsmca: false,
    documentIndicatorFda: false,
    documentIndicatorFcc: false,
    senderIsProducerIndicator: false,
    textileManufacturer: null,
    unitWeight: 0,
    weightUnit: WeightUnit.LB,
    pieces: 0,
    handlingUnitIds: [],
    squareMeters: 0,
};

export const initialAddressFilter: AddressFilter = {
    name: "",
    description: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    companyName: "",
    isShipping: false,
    isBilling: false,
    isShippingDefault: false,
    isBillingDefault: false,
};

export const initialAddressListFiltering: AddressListFiltering = {
    filters: initialAddressFilter,
};

export const initialLaneListFiltering: LaneListFiltering = {
    filters: {
        address: "",
    },
};

export const initialEdiFilter: EdiFilter = {
    shipmentNumber: "",
    //transactionNumber: '',
    poNumber: "",
    bolNumber: "",
    clientOrganization: null,
    threePlOrganization: null,
    //partnerEdiId: '',
    SCAC: "",
    receivedDateFrom: "",
    receivedDateTo: "",
    expireDateFrom: "",
    expireDateTo: "",
    locationOrigin: "",
    locationDestination: "",
    transportType: {
        [TransportType.LTL]: false,
        [TransportType.FTL]: false,
        [TransportType.OTHER]: false,
        [TransportType.PARCEL]: false,
        [TransportType.ENVELOPE]: false,
        [TransportType.PAK]: false,
    },
    /*
    equipmentType: {
        [EquipmentType.DRYBOX]: false,
        [EquipmentType.REEFER]: false,
        [EquipmentType.FLATBED]: false,
        [EquipmentType.SPECIALIZED]: false,
    },
    */
    ediOrderStatus: {
        [EdiOrderStatus.ACCEPTED]: false,
        [EdiOrderStatus.PENDING]: false,
        [EdiOrderStatus.EXPIRED]: false,
        [EdiOrderStatus.DECLINED]: false,
        [EdiOrderStatus.CANCELLED]: false,
    },
    withOrderCreatedType: {
        [WithOrderCreated.YES]: false,
        [WithOrderCreated.NO]: false,
    },
};

export const initialEdiListFiltering: EdiListFiltering = {
    filters: initialEdiFilter,
};

export const initialUserFilter: UserFilter = {
    name: "",
    role: undefined,
    status: [],
    clientOrganization: null,
    currentPage: 1,
};

export const initialUserListFiltering: UserListFiltering = {
    filters: initialUserFilter,
    organizationList: [],
};

export const initialState: Store = {
    appState: initialAppState,
    version: null,
    versionSnackbarOpen: false,
    userInfo: initialUserInfo,
    userOrganization: initialUserOrganization,
    theme: initialTheme,
    snackbar: initialSnackbar,
    notistack: null,
    marketPlace: deepCopy(initialMarketPlace),
    newMarketPlace: deepCopy(initialNewMarketPlace),
    currencyRates: initialCurrencyRates,
    systemStats: initialSystemStats,
    orderDetails: deepCopy(initialOrderDetails),
    // TODO #142: join orderlist related objects under one parent
    orderListFiltering: initialOrderListFiltering,
    orderEvents: initialOrderEvents,
    invoiceListFiltering: initialInvoiceListFiltering,
    defaultAddresses: initialDefaultAddressListState,
    newPickupDate: "",
    addressListFiltering: initialAddressListFiltering,
    ediListFiltering: initialEdiListFiltering,
    userListFiltering: initialUserListFiltering,
    organizationListFiltering: initialOrganizationListFiltering,
    language: Language.EN,
    pendingPriceReview: initialPendingPriceReview,
    applyPaymentInfo: initialApplyPaymentInfo,
    requestedDemoDialogOpen: false,
    requestedRegisterDialogOpen: false,
    laneListFiltering: initialLaneListFiltering,
    isDefaultBillingAddressesCallExecuted: false,
};
