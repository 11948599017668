/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import type en from './ThreePlRegionZone.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'Zone':'Zone',
    ORIGIN: 'Origine',
    DESTINATION: 'Destination',
    BOTH: 'Origin et destination'
}))()
