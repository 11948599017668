/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    'Zone':'Zone',
    ORIGIN: 'Origin',
    DESTINATION: 'Destination',
    BOTH: 'Both'
}

