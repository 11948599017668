import {
    type Country as CountryCode,
    type CoverageCommodityType,
    type Currency as CurrencyCode,
    type EquipmentType,
    type InvoiceType,
    type LaneType,
    type MarginVersion,
    type OrderStatus,
    type OrganizationType,
    type PaymentTypeOption,
    PickupStatus,
    type Provider,
    RfqStatus,
    type ServiceLevelType,
    type ShipmentIdentifierType,
    ShipmentStatus,
    type ShippingMode,
    type TariffType,
    type TrackingUpdateExceptionDetail,
    type TrackingUpdateExceptionSummary,
    TrackingUpdateStatus,
    type TrackingUpdateStatusReason,
    type TrackingUpdateStopType,
    type TrackingUpdateType,
    type TransportType,
    type UuidV4,
    type UuidV5,
    type CarrierType,
} from '@lazr/openapi-client'

import { isFreightService, isMailingService, MAILING_COVERAGE_INSURANCE, FREIGHT_COVERAGE_INSURANCE } from '@lazr/utilities'
import moment from 'moment'
import { type Action, Priority } from '../ui/lib/constants'
import { Country, type CountryProps } from './Country'
import { Currency, type CurrencyProps } from './Currency'
import type { Organization } from './Organization'
import { OtherUser, type OtherUserProps } from './OtherUser'

interface OrderListItemOrganization {
    id: UuidV4
    type: OrganizationType
    name: string
    onboarding: string
}

export interface OrderListItemPriceReview {
    id: UuidV4
    orderId: UuidV4
    rawCad?: number
    rawUsd?: number
    totalCad: number
    totalUsd: number
    currency: CurrencyCode
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
}

export interface OrderListItemTrackingUpdate {
    id: UuidV4
    shipmentId: UuidV4
    trackingUpdateTime: string
    retrievalDateTime: string
    type: TrackingUpdateType
    status: TrackingUpdateStatus
    statusReason: TrackingUpdateStatusReason
    statusMessage: string
    exceptionSummary: TrackingUpdateExceptionSummary
    exceptionDetail: TrackingUpdateExceptionDetail
    stopType: TrackingUpdateStopType
    stopNumber: number
    apiStopId: string
    streetAddress: string
    city: string
    state: string
    country: CountryCode
    postalCode: string
    latitude: number
    longitude: number
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
}

export interface ShipmentIdentifier {
    id: UuidV4
    shipmentId?: UuidV4
    type?: ShipmentIdentifierType
    value?: string
}

interface OrderListItemShipment {
    id: UuidV4
    orderId: UuidV4
    lastUpdatedByUserId: UuidV4
    lastUpdatedAt: string
    status: ShipmentStatus
    pickupStatus: PickupStatus
    estimatedDeliveryDate: string
    carrierId: UuidV4
    transitDaysMin: number
    transitDaysMax: number
    apiId: string
    apiLegId: string
    apiPickupDateTime: string
    apiBillOfLadingUrl: string
    apiPackingVisualizationUrl: string
    specialInstructionsForPickup: string
    specialInstructionsForDelivery: string
    pullTracking: boolean
    trackingEngaged: boolean
    updatedAt: string
    createdAt: string
    trackingUpdates: OrderListItemTrackingUpdate[]
    shipmentIdentifiers: ShipmentIdentifier[]
}

export interface OrderListItemQuote {
    id: UuidV4
    rfqId: UuidV4
    isCustomQuote: boolean
    requiresCustomQuote: boolean
    requiresValidation: boolean
    provider: Provider
    apiCarrierQuoteNumber: string
    carrierId: UuidV4
    carrierCode: string
    carrierDisplayName: string
    serviceLevel: string
    serviceLevelCode: string
    currency: CurrencyCode
    preferredCurrency: CurrencyCode
    lazrCadToUsdRate: number
    lazrUsdToCadRate: number
    exchangedAt: string
    transitDaysMin: number
    transitDaysMax: number
    costRawCad?: number
    costRawUsd?: number
    costMarginCad?: number
    costMarginUsd?: number
    costTotalCad: number
    costTotalUsd: number
    costTransactionFeeCad: number
    costTransactionFeeUsd: number
    additionalInfo: string
    estimatedTimeOfArrival: string
    estimatedDeliveryDate: string | null
    validUntil: string
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    tariffType: TariffType
    isHidden: boolean
    marginVersion: MarginVersion
    shippingMode?: ShippingMode | null
    laneType?: LaneType | null
    serviceLevelType: ServiceLevelType | null
    quoteCarrier: OrderListItemQuoteCarrier
}

export interface OrderListItemQuoteCarrier {
    id: UuidV4
    name: string
    idValue: string
    type: CarrierType
    apiCarrierAccountCode: string
    createdAt: string
    updatedAt: string
    etdUploadMaxSize: number | null
}

export interface OrderListItemInvoice {
    id: UuidV4
    externalNumber: string
    invoicePrice: number
    type: InvoiceType
    currency: CurrencyCode
    dueDate: string
    taxes: {
        id: UuidV4
        total: number
        type: string
    }[]
    createdAt: string
}

interface OrderListItemRfqProps {
    id: UuidV4
    orderId: UuidV4
    submittedAt: string
    bookedAt: string
    status: RfqStatus
    customQuoteRequested: boolean
    transportType: TransportType
    equipmentType: EquipmentType
    selectedQuote: OrderListItemQuote | null
    originStreetAddress: string
    originStreetAddress2: string
    originStreetAddress3: string
    originCity: string
    originState: string
    originCountry: CountryProps
    originPostalCode: string
    originPickupDate: string
    originName: string
    originContactName: string
    originContactEmails: string[]
    originContactPhone: string
    originContactPhoneExtension: string
    originCompanyName: string
    originNotifyShipper: boolean
    originPickupOpenTime: string
    originPickupCloseTime: string
    originPickupInstructions: string
    originPoNumber: string
    destinationStreetAddress: string
    destinationStreetAddress2: string
    destinationStreetAddress3: string
    destinationCity: string
    destinationState: string
    destinationCountry: CountryProps
    destinationPostalCode: string
    destinationDeliveryDate: string
    destinationName: string
    destinationContactName: string
    destinationContactEmails: string[]
    destinationContactPhone: string
    destinationContactPhoneExtension: string
    destinationCompanyName: string
    destinationNotifyReceiver: boolean
    destinationDeliveryOpenTime: string
    destinationDeliveryCloseTime: string
    destinationDeliveryInstructions: string
    destinationPoNumber: string
    customsBroker: string
    linearFeet: number
    skidSpots: number
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean
    isBolUploaded: boolean
    isLabelUploaded: boolean
    coverageCommodityType: CoverageCommodityType
    shipmentCoverageValue: number
    reverseMargins: boolean
    resultsSearchStartedAt: string
    resultsSearchInProgress: boolean
    resultsCompleted: Record<string, boolean>
    lastUpdatedByUserId: UuidV4
    submittedByUserId: UuidV4
    updatedAt: string
    createdAt: string
    staticMapUrl: {
        '600x240': string
        '187x187': string
    }
    addressId: UuidV4
    billingPo: string | null
    paymentType: PaymentTypeOption | null
}

interface OrderListItemRfq {
    id: UuidV4
    orderId: UuidV4
    submittedAt: string
    bookedAt: string
    status: RfqStatus
    customQuoteRequested: boolean
    equipmentType: EquipmentType
    transportType: TransportType
    selectedQuote: OrderListItemQuote | null
    originStreetAddress: string
    originStreetAddress2: string
    originStreetAddress3: string
    originCity: string
    originState: string
    originCountry: Country
    originPostalCode: string
    originPickupDate: string
    originName: string
    originContactName: string
    originContactEmails: string[]
    originContactPhone: string
    originContactPhoneExtension: string
    originCompanyName: string
    originNotifyShipper: boolean
    originPickupOpenTime: string
    originPickupCloseTime: string
    originPickupInstructions: string
    originPoNumber: string
    destinationStreetAddress: string
    destinationStreetAddress2: string
    destinationStreetAddress3: string
    destinationCity: string
    destinationState: string
    destinationCountry: Country
    destinationPostalCode: string
    destinationDeliveryDate: string
    destinationName: string
    destinationContactName: string
    destinationContactEmails: string[]
    destinationContactPhone: string
    destinationContactPhoneExtension: string
    destinationCompanyName: string
    destinationNotifyReceiver: boolean
    destinationDeliveryOpenTime: string
    destinationDeliveryCloseTime: string
    destinationDeliveryInstructions: string
    destinationPoNumber: string
    customsBroker: string
    linearFeet: number
    skidSpots: number
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean
    isBolUploaded: boolean
    isLabelUploaded: boolean
    coverageCommodityType: CoverageCommodityType
    shipmentCoverageValue: number
    reverseMargins: boolean
    resultsSearchStartedAt: string
    resultsSearchInProgress: boolean
    resultsCompleted: Record<string, boolean>
    lastUpdatedByUserId: UuidV4
    submittedByUserId: UuidV4
    updatedAt: string
    createdAt: string
    staticMapUrl: {
        '600x240': string
        '187x187': string
    }
    addressId: UuidV4
    billingPo: string | null
    paymentType: PaymentTypeOption | null
}

export interface OrderListItemProps {
    billingCurrency: CurrencyProps
    lazrCadToUsdRate: number
    clientNumber: string
    clientOrganization: OrderListItemOrganization
    clientPrivateNotes: string
    createdAt: string
    createdByUser: OtherUserProps | null
    id: UuidV4
    isOpen: boolean
    lastOpenAt: string
    lastUpdatedByUserId: UuidV4
    number: string
    openUserId: UuidV4
    priceReviews: OrderListItemPriceReview[]
    shipments: OrderListItemShipment[]
    rfq: OrderListItemRfqProps
    status: OrderStatus
    systemPrivateNotes: string
    threePlNumber: string
    threePlOrganization: OrderListItemOrganization | null
    threePlPrivateNotes: string
    updatedAt: string
    lazrUsdToCadRate: number
    invoices: OrderListItemInvoice[]
    ediOrderId: null | UuidV5
    documents: ResultDocument[]
    marketplaceVersion: number
}

interface ResultDocument {
    id: UuidV4
    name: string
    type: DocumentType
    orderId: UuidV4
    organizationId: UuidV4
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    isGenerated: boolean
    hasBarcode: boolean
    transportInvoiceNumber: string | null
}

export class OrderListItem {
    public billingCurrency: Currency
    public lazrCadToUsdRate: number
    public clientNumber: string
    public clientOrganization: OrderListItemOrganization
    public clientPrivateNotes: string
    public createdAt: string
    public createdByUser: OtherUser | null
    public id: UuidV4
    public isOpen: boolean
    public lastOpenAt: string
    public lastUpdatedByUserId: UuidV4
    public number: string
    public openUserId: UuidV4
    public priceReviews: OrderListItemPriceReview[]
    public shipments: OrderListItemShipment[]
    public rfq: OrderListItemRfq
    public status: OrderStatus
    public systemPrivateNotes: string
    public threePlNumber: string
    public threePlOrganization: OrderListItemOrganization | null
    public threePlPrivateNotes: string
    public updatedAt: string
    public lazrUsdToCadRate: number
    public invoices: OrderListItemInvoice[]
    public ediOrderId: null | UuidV5
    public documents: ResultDocument[]
    public marketplaceVersion: number

    constructor(props: OrderListItemProps) {
        this.billingCurrency = new Currency(props.billingCurrency)
        this.lazrCadToUsdRate = props.lazrCadToUsdRate
        this.clientNumber = props.clientNumber
        this.clientOrganization = props.clientOrganization
        this.clientPrivateNotes = props.clientPrivateNotes
        this.createdAt = props.createdAt
        this.createdByUser = props.createdByUser ? new OtherUser(props.createdByUser) : null
        this.id = props.id
        this.isOpen = props.isOpen
        this.lastOpenAt = props.lastOpenAt
        this.lastUpdatedByUserId = props.lastUpdatedByUserId
        this.number = props.number
        this.openUserId = props.openUserId && props.openUserId
        this.priceReviews = props.priceReviews
        this.shipments = props.shipments
        this.rfq = {
            ...props.rfq,
            originCountry: new Country(props.rfq.originCountry),
            destinationCountry: new Country(props.rfq.destinationCountry),
        }
        this.status = props.status
        this.systemPrivateNotes = props.systemPrivateNotes
        this.threePlNumber = props.threePlNumber
        this.threePlOrganization = props.threePlOrganization
        this.threePlPrivateNotes = props.threePlPrivateNotes
        this.updatedAt = props.updatedAt
        this.lazrUsdToCadRate = props.lazrUsdToCadRate
        this.invoices = props.invoices
        this.ediOrderId = props.ediOrderId
        this.documents = props.documents
        this.marketplaceVersion = props.marketplaceVersion
    }

    get activeShipment(): OrderListItemShipment | null {
        return this.shipments.find((shipment) => shipment.status === ShipmentStatus.ACTIVE) ?? null
    }

    get latestUsableTrackingUpdate(): OrderListItemTrackingUpdate | null {
        return (
            this.activeShipment?.trackingUpdates
                ?.filter(
                    (trackingUpdate) =>
                        trackingUpdate.status !== TrackingUpdateStatus.CARRIER_DISPATCHED &&
                        trackingUpdate.status !== TrackingUpdateStatus.INFO,
                )
                .sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1
                    }
                    if (a.createdAt < b.createdAt) {
                        return 1
                    }

                    return 0
                })[0] ?? null
        )
    }

    getAction = (organization: Organization): Action => {
        let timeSensitiveLabel: string | undefined
        let timeSensitivePriority: Priority | undefined
        if (moment(this.rfq.originPickupDate).isSame(moment(), 'day')) {
            timeSensitiveLabel = '(Same-Day Pickup)'
            if (moment(this.rfq.destinationDeliveryDate).isSame(moment(), 'day')) {
                timeSensitiveLabel = '(Hotshot)'
            }
            timeSensitivePriority = Priority.HIGH
        }

        if (organization.isThreePlOrSystemAsThreePl(this.threePlOrganization?.id)) {
            if (this.rfq.status === RfqStatus.SUBMITTED) {
                if (this.rfq.customQuoteRequested) {
                    return {
                        label: `Custom Quote Requested${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                        priority: timeSensitivePriority ?? Priority.MODERATE,
                    }
                }

                return {
                    label: `Order Submitted${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.ACCEPTED) {
                return {
                    label: `Revise or Book or Abort${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.REJECTED) {
                return {
                    label: `Revise or Abort${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }
            if (
                this.rfq.isBookedWithCoverage &&
                ((this.rfq.shipmentCoverageValue >= FREIGHT_COVERAGE_INSURANCE && isFreightService(this.rfq.transportType)) ||
                    (this.rfq.shipmentCoverageValue >= MAILING_COVERAGE_INSURANCE && isMailingService(this.rfq.transportType)))
            ) {
                return {
                    label: 'Generate Certificate of Insurance',
                    priority: Priority.MODERATE,
                }
            }
            if (this.rfq.status === RfqStatus.BOOKED) {
                return {
                    label: `Dispatch or Abort${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.CARRIER_DISPATCHED && !this.rfq.isBolUploaded && isFreightService(this.rfq.transportType)) {
                return {
                    label: 'Generate BOL',
                    priority: Priority.MODERATE,
                }
            }

            if (this.rfq.status === RfqStatus.CARRIER_DISPATCHED && !this.rfq.isLabelUploaded && isMailingService(this.rfq.transportType)) {
                return {
                    label: 'Generate Labels',
                    priority: Priority.MODERATE,
                }
            }

            if (this.rfq.status === RfqStatus.DRAFT && this.rfq.originStreetAddress && this.rfq.destinationStreetAddress) {
                return {
                    label: `Submit or Cancel${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.REVISED) {
                return {
                    label: `Accept or Reject or Cancel${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.PENDING_CANCELLATION) {
                return {
                    label: 'Pending cancellation',
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }
        } else if (organization.isClientOrSystemAsClient(this?.clientOrganization?.id)) {
            if (this.rfq.status === RfqStatus.DRAFT && this.rfq.originStreetAddress && this.rfq.destinationStreetAddress) {
                return {
                    label: `Submit or Cancel${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.rfq.status === RfqStatus.REVISED) {
                return {
                    label: `Accept or Reject or Cancel${timeSensitiveLabel ? ` ${timeSensitiveLabel}` : ''}`,
                    priority: timeSensitivePriority ?? Priority.MEDIUM,
                }
            }

            if (this.invoices.some((invoice) => invoice.dueDate < moment().startOf('day').toISOString())) {
                return {
                    label: 'Past due invoice',
                    priority: Priority.LOW,
                }
            }
        }
        if (organization.isSystem()) {
            if (
                this.rfq.isBookedWithCoverage &&
                ((this.rfq.shipmentCoverageValue >= 8000 && isFreightService(this.rfq.transportType)) ||
                    (this.rfq.shipmentCoverageValue >= 1000 && isMailingService(this.rfq.transportType)))
            ) {
                return {
                    label: 'Generate Certificate of Insurance',
                    priority: Priority.MODERATE,
                }
            }
            if (
                this.rfq.status === RfqStatus.CARRIER_DISPATCHED &&
                this.shipments.some(
                    (shipment) => shipment.status === ShipmentStatus.ACTIVE && shipment.pickupStatus === PickupStatus.FAILED,
                )
            ) {
                return {
                    label: 'Pickup Schedule failed',
                    priority: Priority.HIGH,
                }
            }
        }

        // TO DO: Crash the app with ACME when switching back to System user when "throw" is on because no conditions are matching above.
        return {
            label: '',
            priority: Priority.LOW,
        }
        //throw new Error('Expected action for actionable quote was not found')
    }
}
