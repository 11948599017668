import { 
    OrderQuoteService,
    RfqClientThreeplAssigned,
    OrderRfqQuoteAccept,
    OrderRfqQuoteCancel,
    UuidV4,
    ErrorResponse,
    ApiError,
    Currency,
    CustomQuote,
    //RequiredCustomQuote,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

// export interface QuoteCostCharge {
//     code: string
//     description: string
//     rawCad: number | null
//     rawUsd: number | null
//     marginCad: number | null
//     marginUsd: number | null
//     totalCad: number | null
//     totalUsd: number | null
// }

// interface OrderQuoteThreePLAttributes {
//     orderId: string
//     rfqId: string
//     quoteId: string
//     threePlId: string
//     additionalInfo: string
//     currency: Currency,
//     preferredCurrency: Currency,
//     transitDaysMin: number,
//     transitDaysMax: number,
//     estimatedTimeOfArrival: string | null,
//     validUntil: string,
//     costRawCad: number,
//     costRawUsd: number,
//     costMarginCad: 0,
//     costMarginUsd: 0,
//     costTotalCad: number,
//     costTotalUsd: number,
//     costTransactionFeeCad: 0,
//     costTransactionFeeUsd: 0,
//     quoteCostCharges: QuoteCostCharge[]
// }

export const OrderQuoteApiService = Object.freeze({
    getRfqClientThreeplAssigned: async (rfqClientThreeplAssigned: Partial<RfqClientThreeplAssigned>): Promise<RfqClientThreeplAssigned[] | undefined> => {
        console.debug('OrderQuoteApiService > getRfqClientThreeplAssigned', rfqClientThreeplAssigned)
        if (
            !rfqClientThreeplAssigned.threePlOrganizationId
            && !rfqClientThreeplAssigned.rfqId
        ) {
            throw new Error('Missing required field: Client Organization ID and RFQ ID.')
        }

        try {
            const response = await OrderQuoteService.getRfqClientThreeplAssigned(
                rfqClientThreeplAssigned.rfqId!,
                rfqClientThreeplAssigned.clientOrganizationId,
                rfqClientThreeplAssigned.threePlOrganizationId,
            )
            const rfqClientThreeplAssignedResult: RfqClientThreeplAssigned[] | undefined = response.data
            return rfqClientThreeplAssignedResult
            //return await OrderQuoteService.getCustomOrderQuoteThreePl(threePlId, rfqId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            //logger.debug('decline', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get the 3PL quote status')
            }
            throw new Error('Unable to get the 3PL quote status')
        }
    },
    accept: async (orderQuoteThreePl: OrderRfqQuoteAccept): Promise<void> => {
        //console.debug('OrderQuoteApiService > orderQuoteThreePLAttributes', orderQuoteThreePl)
        if (
            !orderQuoteThreePl.orderId 
            || !orderQuoteThreePl.rfqId
            || !orderQuoteThreePl.threePlId 
            || !orderQuoteThreePl.quoteId
        ) {
            throw new Error('Missing required field(s): Order ID, RFQ ID, 3PL Organization ID and/or Quote ID.')
        }

        try {
            await OrderQuoteService.acceptOrderRfqQuoteThreePl(orderQuoteThreePl)
        } catch (error: any) {
            handleUnauthorizedException(error)
            //logger.debug('decline', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to decline the order')
            }
            throw new Error('Unable to decline the order')
        }
    },
    decline: async (orderId: UuidV4): Promise<void> => {
        try {
            await OrderQuoteService.declineOrderRfqQuoteThreePl({ orderId: orderId })
        } catch (error: any) {
            handleUnauthorizedException(error)
            //logger.debug('decline', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to decline the order')
            }
            throw new Error('Unable to decline the order')
        }
    },
    cancel: async(orderRfqQuoteCancel: OrderRfqQuoteCancel): Promise<boolean> => {
        console.debug('CANCEL', orderRfqQuoteCancel)
        if (!orderRfqQuoteCancel.quoteId || !orderRfqQuoteCancel.threePlId) {
            throw new Error('Missing required field: RFQ ID, 3PL Organization ID.')
        }

        let cancelOrderQuoteResponse
        try {
            cancelOrderQuoteResponse = await OrderQuoteService.cancelOrderRfqQuoteThreePl(orderRfqQuoteCancel)
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to cancel order quote')
            }
            throw new Error('Unable to cancel order quote')
        }

        if (!cancelOrderQuoteResponse.data) {
            throw new Error('Unable to cancel order quote')
        }

        return cancelOrderQuoteResponse as unknown as boolean
    },
    addOrderRfqQuoteThreePl: async (customQuote: CustomQuote): Promise<CustomQuote['id']> => {
        console.debug('OrderQuoteApiService > addOrderRfqQuoteThreePl', customQuote)
        if (!customQuote) {
            throw new Error('Missing custom quote required field(s).')
        }

        let addCustomQuoteResponse
        try {
            addCustomQuoteResponse = await OrderQuoteService.addOrderRfqQuoteThreePl({
                orderId: customQuote.orderId,
                currency: customQuote.currency,
                preferredCurrency: customQuote.preferredCurrency,
                transitDaysMin: customQuote.transitDaysMin,
                transitDaysMax: customQuote.transitDaysMax,
                costRawCad: customQuote.costRawCad,
                costRawUsd: customQuote.costRawUsd,
                costMarginCad: customQuote.costMarginCad,
                costMarginUsd: customQuote.costMarginUsd,
                costTotalCad: customQuote.costTotalCad,
                costTotalUsd: customQuote.costTotalUsd,
                costTransactionFeeCad: customQuote.costTransactionFeeCad,
                costTransactionFeeUsd: customQuote.costTransactionFeeUsd,
                additionalInfo: customQuote.additionalInfo,
                estimatedTimeOfArrival: customQuote.estimatedTimeOfArrival,
                validUntil: customQuote.validUntil,
                quoteCharges: customQuote.quoteCharges.map((quoteCharges, index) => {
                    if (quoteCharges.totalUsd === null || quoteCharges.totalCad === null || quoteCharges.marginUsd === null ||
                        quoteCharges.marginCad === null || quoteCharges.rawUsd === null || quoteCharges.rawCad === null
                    ) {
                        throw new Error('Unable to add custom quote')
                    }

                    return {
                        totalUsd: quoteCharges.totalUsd,
                        totalCad: quoteCharges.totalCad,
                        marginUsd: quoteCharges.marginUsd,
                        marginCad: quoteCharges.marginCad,
                        rawUsd: quoteCharges.rawUsd,
                        rawCad: quoteCharges.rawCad,
                        description: quoteCharges.description,
                        code: quoteCharges.code,
                        seqNumber: index,
                    }
                }),
            })
        } catch (error: any) {
            handleUnauthorizedException(error)

            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add custom quote')
            }
            throw new Error('Unable to add custom quote')
        }

        if (!addCustomQuoteResponse.data) {
            throw new Error('Unable to add custom quote')
        }

        return addCustomQuoteResponse.data
    }
})