/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddThreePlRegion } from '../models/AddThreePlRegion';
import type { AssignThreePlToOrganization } from '../models/AssignThreePlToOrganization';
import type { Country } from '../models/Country';
import type { DeleteThreePlRegion } from '../models/DeleteThreePlRegion';
import type { EditOrganizationRequest } from '../models/EditOrganizationRequest';
import type { Onboarding } from '../models/Onboarding';
import type { OrganizationPermissionRequest } from '../models/OrganizationPermissionRequest';
import type { OrganizationSettingsRequest } from '../models/OrganizationSettingsRequest';
import type { OrganizationStatus } from '../models/OrganizationStatus';
import type { OrganizationThreePlStatus } from '../models/OrganizationThreePlStatus';
import type { OrganizationType } from '../models/OrganizationType';
import type { SuccessResponse_AddOrganizationDataResult_ } from '../models/SuccessResponse_AddOrganizationDataResult_';
import type { SuccessResponse_DeleteThreePlRegionResult_ } from '../models/SuccessResponse_DeleteThreePlRegionResult_';
import type { SuccessResponse_EditOrganizationResponse_ } from '../models/SuccessResponse_EditOrganizationResponse_';
import type { SuccessResponse_GetCurrentOrganizationResponse_ } from '../models/SuccessResponse_GetCurrentOrganizationResponse_';
import type { SuccessResponse_GetLinkedOrganizationsResponse_Array_ } from '../models/SuccessResponse_GetLinkedOrganizationsResponse_Array_';
import type { SuccessResponse_GetOrganizationByIdResponse_ } from '../models/SuccessResponse_GetOrganizationByIdResponse_';
import type { SuccessResponse_GetOrganizationBySlugResponse_ } from '../models/SuccessResponse_GetOrganizationBySlugResponse_';
import type { SuccessResponse_GetOrganizationPermissionsResponse_ } from '../models/SuccessResponse_GetOrganizationPermissionsResponse_';
import type { SuccessResponse_GetOrganizationRolesResponse_ } from '../models/SuccessResponse_GetOrganizationRolesResponse_';
import type { SuccessResponse_GetOrganizationsResponse_ } from '../models/SuccessResponse_GetOrganizationsResponse_';
import type { SuccessResponse_GetThreePlRegionsResponse_ } from '../models/SuccessResponse_GetThreePlRegionsResponse_';
import type { SuccessResponse_GetThreePlsResponse_ } from '../models/SuccessResponse_GetThreePlsResponse_';
import type { SuccessResponse_GetUserOrganizationsByUserIdResponse_ } from '../models/SuccessResponse_GetUserOrganizationsByUserIdResponse_';
import type { SuccessResponse_OrganizationPermissionResponse_ } from '../models/SuccessResponse_OrganizationPermissionResponse_';
import type { SuccessResponse_OrganizationSettingsResponse_ } from '../models/SuccessResponse_OrganizationSettingsResponse_';
import type { SuccessResponse_PendingInvoicesOrganizationsResponse_ } from '../models/SuccessResponse_PendingInvoicesOrganizationsResponse_';
import type { SuccessResponse_ThreePl_ } from '../models/SuccessResponse_ThreePl_';
import type { ThreePlRegionType } from '../models/ThreePlRegionType';
import type { ThreePlRegionZone } from '../models/ThreePlRegionZone';
import type { TransportType } from '../models/TransportType';
import type { UnassignThreePlToOrganization } from '../models/UnassignThreePlToOrganization';
import type { UpdateOrganizationThreePlParams } from '../models/UpdateOrganizationThreePlParams';
import type { UserStatus } from '../models/UserStatus';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Add three Pls region.
     * @param requestBody The data
     * @returns SuccessResponse_AddOrganizationDataResult_ OK
     * @throws ApiError
     */
    public static async addThreePlRegion(
requestBody: AddThreePlRegion,
): Promise<SuccessResponse_AddOrganizationDataResult_> {
        const result = await __request({
            method: 'POST',
            path: `/add-three-pl-region`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Assign three Pls to organization.
     * @param requestBody The data
     * @returns SuccessResponse_AddOrganizationDataResult_ OK
     * @throws ApiError
     */
    public static async assignThreePlToOrganization(
requestBody: AssignThreePlToOrganization,
): Promise<SuccessResponse_AddOrganizationDataResult_> {
        const result = await __request({
            method: 'POST',
            path: `/assign-three-pl-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete three pl region
     * @param requestBody The data
     * @returns SuccessResponse_DeleteThreePlRegionResult_ OK
     * @throws ApiError
     */
    public static async deleteThreePlRegion(
requestBody: DeleteThreePlRegion,
): Promise<SuccessResponse_DeleteThreePlRegionResult_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-three-pl-region`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit the organization
     * @param requestBody The data
     * @returns SuccessResponse_EditOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async editOrganization(
requestBody: EditOrganizationRequest,
): Promise<SuccessResponse_EditOrganizationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Unassign three Pls to organization.
     * @param requestBody The data
     * @returns SuccessResponse_AddOrganizationDataResult_ OK
     * @throws ApiError
     */
    public static async unassignThreePlToOrganization(
requestBody: UnassignThreePlToOrganization,
): Promise<SuccessResponse_AddOrganizationDataResult_> {
        const result = await __request({
            method: 'POST',
            path: `/unassign-three-pl-organization`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the organization permission
     * @param requestBody The data
     * @returns SuccessResponse_OrganizationPermissionResponse_ OK
     * @throws ApiError
     */
    public static async updatePermissions(
requestBody: OrganizationPermissionRequest,
): Promise<SuccessResponse_OrganizationPermissionResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-organization-permission`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update the organization settings
     * @param requestBody The data
     * @returns SuccessResponse_OrganizationSettingsResponse_ OK
     * @throws ApiError
     */
    public static async updateSettings(
requestBody: OrganizationSettingsRequest,
): Promise<SuccessResponse_OrganizationSettingsResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/update-organization-settings`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Update organization three Pl
     * @param requestBody The data
     * @returns SuccessResponse_AddOrganizationDataResult_ OK
     * @throws ApiError
     */
    public static async updateOrganizationThreePl(
requestBody: UpdateOrganizationThreePlParams,
): Promise<SuccessResponse_AddOrganizationDataResult_> {
        const result = await __request({
            method: 'POST',
            path: `/update-organization-three-pl`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get your own organization
     * @returns SuccessResponse_GetCurrentOrganizationResponse_ OK
     * @throws ApiError
     */
    public static async getCurrentOrganization(): Promise<SuccessResponse_GetCurrentOrganizationResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/current-organization`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organizations linked with the calling user
     * @returns SuccessResponse_GetLinkedOrganizationsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLinkedOrganizations(): Promise<SuccessResponse_GetLinkedOrganizationsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/get-linked-organizations`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an organization by its ID
     * @param organizationId The organization ID
     * @returns SuccessResponse_GetOrganizationByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationById(
organizationId: string,
): Promise<SuccessResponse_GetOrganizationByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-by-id/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an organization by its ID
     * @param slug The URL friendly organization name
     * @returns SuccessResponse_GetOrganizationBySlugResponse_ 
     * @throws ApiError
     */
    public static async getOrganizationBySlug(
slug: string,
): Promise<SuccessResponse_GetOrganizationBySlugResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-by-slug/${slug}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organization permissions by organization id (system organizations only)
     * @returns SuccessResponse_GetOrganizationPermissionsResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationPermissions(): Promise<SuccessResponse_GetOrganizationPermissionsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-permissions`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organization roles by organization id (system organizations only)
     * @param organizationId 
     * @returns SuccessResponse_GetOrganizationRolesResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizationRoles(
organizationId: string,
): Promise<SuccessResponse_GetOrganizationRolesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-roles/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of organizations (system organizations only)
     * @param page 
     * @param resultsPerPage 
     * @param orderBy 
     * @param order 
     * @param organizationName 
     * @param organizationStatuses 
     * @param salesRepId 
     * @param withDetail 
     * @param adminStatus 
     * @param onboarding 
     * @param isGmx 
     * @param organizationTypes 
     * @returns SuccessResponse_GetOrganizationsResponse_ OK
     * @throws ApiError
     */
    public static async getOrganizations(
page?: number,
resultsPerPage?: number,
orderBy?: 'name' | 'adminEmail' | 'adminName' | 'createdAt' | 'repName' | 'cofaceStatus' | 'cofaceCreditLimit' | 'maxFreightOrders' | 'maxParcelOrders' | 'creditLimit' | 'creditInUseCad' | 'lastLoginAt' | 'lastGhostAt' | 'lastOrderAt' | 'numberOfUsers' | 'registerUrl',
order?: 'asc' | 'desc',
organizationName?: string,
organizationStatuses?: Array<OrganizationStatus>,
salesRepId?: UuidV4,
withDetail?: boolean,
adminStatus?: UserStatus,
onboarding?: Array<Onboarding>,
isGmx?: boolean,
organizationTypes?: Array<OrganizationType>,
): Promise<SuccessResponse_GetOrganizationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/organizations`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'orderBy': orderBy,
                'order': order,
                'organizationName': organizationName,
                'organizationStatuses': organizationStatuses,
                'salesRepId': salesRepId,
                'withDetail': withDetail,
                'adminStatus': adminStatus,
                'onboarding': onboarding,
                'isGmx': isGmx,
                'organizationTypes': organizationTypes,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of pending invoices organizations
     * @returns SuccessResponse_PendingInvoicesOrganizationsResponse_ OK
     * @throws ApiError
     */
    public static async getPendingInvoicesOrganizations(): Promise<SuccessResponse_PendingInvoicesOrganizationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/pending-invoices-organizations`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pls.
     * @param id The organization ID
     * @returns SuccessResponse_ThreePl_ OK
     * @throws ApiError
     */
    public static async getThreePlById(
id: UuidV4,
): Promise<SuccessResponse_ThreePl_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pl-by-id`,
            query: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pl by NOME.
     * @param nome The organization NOME
     * @returns SuccessResponse_ThreePl_ OK
     * @throws ApiError
     */
    public static async getThreePlByName(
nome: string,
): Promise<SuccessResponse_ThreePl_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pl-by-name`,
            query: {
                'nome': nome,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pls.
     * @param organizationId The organization ID
     * @returns SuccessResponse_GetThreePlRegionsResponse_ OK
     * @throws ApiError
     */
    public static async getThreePlRegionsByOrganizationId(
organizationId: UuidV4,
): Promise<SuccessResponse_GetThreePlRegionsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/regions-by-organization-id`,
            query: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pls.
     * @param threePlId The Three Pl ID
     * @param zone 
     * @param countries 
     * @param states 
     * @param type 
     * @param freightServices 
     * @returns SuccessResponse_GetThreePlRegionsResponse_ OK
     * @throws ApiError
     */
    public static async getThreePlRegions(
threePlId?: UuidV4,
zone?: ThreePlRegionZone,
countries?: Array<Country>,
states?: Array<string>,
type?: ThreePlRegionType,
freightServices?: Array<TransportType>,
): Promise<SuccessResponse_GetThreePlRegionsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pl-regions`,
            query: {
                'threePlId': threePlId,
                'zone': zone,
                'countries': countries,
                'states': states,
                'type': type,
                'freightServices': freightServices,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pls.
     * @param organizationId The organization ID
     * @param regionOriginCountries 
     * @param regionDestinationCountries 
     * @param regionOriginStates 
     * @param regionDestinationStates 
     * @param regionZone 
     * @param regionType 
     * @param regionFreightServices 
     * @param name 
     * @returns SuccessResponse_GetThreePlsResponse_ OK
     * @throws ApiError
     */
    public static async getThreePls(
organizationId?: UuidV4,
regionOriginCountries?: Array<Country>,
regionDestinationCountries?: Array<Country>,
regionOriginStates?: Array<string>,
regionDestinationStates?: Array<string>,
regionZone?: ThreePlRegionZone,
regionType?: ThreePlRegionType,
regionFreightServices?: Array<TransportType>,
name?: string,
): Promise<SuccessResponse_GetThreePlsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pls`,
            query: {
                'organizationId': organizationId,
                'regionOriginCountries': regionOriginCountries,
                'regionDestinationCountries': regionDestinationCountries,
                'regionOriginStates': regionOriginStates,
                'regionDestinationStates': regionDestinationStates,
                'regionZone': regionZone,
                'regionType': regionType,
                'regionFreightServices': regionFreightServices,
                'name': name,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Three Pls for Order Management.
     * @param organizationId The organization ID
     * @param regionOriginCountries The origin countries
     * @param regionDestinationCountries The destination countries
     * @param regionOriginStates The origin states
     * @param regionDestinationStates The destination states
     * @param regionType The type to filter(ALLOW/NOT_ALLOW)
     * @param regionFreightServices The transport types to filter
     * @param organizationThreePlStatuses The organization three pl status
     * @param regionZone The zone
     * @returns SuccessResponse_GetThreePlsResponse_ OK
     * @throws ApiError
     */
    public static async getThreePlsOrderManagement(
organizationId: UuidV4,
regionOriginCountries: Array<Country>,
regionDestinationCountries: Array<Country>,
regionOriginStates: Array<string>,
regionDestinationStates: Array<string>,
regionType: ThreePlRegionType,
regionFreightServices: Array<TransportType>,
organizationThreePlStatuses: Array<OrganizationThreePlStatus>,
regionZone?: ThreePlRegionZone,
): Promise<SuccessResponse_GetThreePlsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pls-order-management`,
            query: {
                'organizationId': organizationId,
                'regionOriginCountries': regionOriginCountries,
                'regionDestinationCountries': regionDestinationCountries,
                'regionOriginStates': regionOriginStates,
                'regionDestinationStates': regionDestinationStates,
                'regionType': regionType,
                'regionFreightServices': regionFreightServices,
                'organizationThreePlStatuses': organizationThreePlStatuses,
                'regionZone': regionZone,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get user's organizations by user ID
     * @param userId The User ID
     * @returns SuccessResponse_GetUserOrganizationsByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getUserOrganizationsByUserId(
userId: string,
): Promise<SuccessResponse_GetUserOrganizationsByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/user-organizations-by-user-id/${userId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}