import { OrganizationType, UuidV4 } from '@lazr/openapi-client'
import {
    Country,
    ThreePlRegionType,
    ThreePlRegionZone,
    TransportType,
    OrganizationThreePlStatus,
} from '@lazr/enums'

export interface OrganizationThreePlList {
    threePls: OrganizationThreePl[]
}

export interface OrganizationThreePlProps {
    id: UuidV4
    name: string
    exemptFromTaxes: boolean
    type: OrganizationType
    regions: ThreePlRegion[]
    managedQuotes?: ManagedQuote[]
    status: OrganizationThreePlStatus
}

export interface ThreePlFreightService {
    id: UuidV4
    threePlId: UuidV4
    freightService: TransportType
}

export interface ThreePlRegion {
    id: UuidV4
    threePlId: UuidV4
    country: Country
    state: string
    type: ThreePlRegionType
    zone: ThreePlRegionZone
    freightServices: TransportType[]
}

export interface ManagedQuote {
    id: string
    organizationId: string
}

export class OrganizationThreePl {
    public id: UuidV4
    public name: string
    public type: OrganizationType
    public exemptFromTaxes: boolean
    public regions: ThreePlRegion[]
    public managedQuotes?: ManagedQuote[]
    public status: OrganizationThreePlStatus


    constructor (props: OrganizationThreePlProps) {
        this.id = props.id
        this.name = props.name
        this.regions = props.regions
        this.type = props.type
        this.exemptFromTaxes = props.exemptFromTaxes
        this.managedQuotes = props.managedQuotes
        this.status = props.status
    }
}
